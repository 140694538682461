.header {
  background: url('/assets/img/bg.jpg') center center no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  .logo img {
    opacity: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    animation: deblur 3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }
  p {
    opacity: 0;
    font-size: 18px;
    color: #898B8E;
    animation: deblur 3s 2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.65);
    animation: fade 5s 1s;
    animation-fill-mode: forwards;
    opacity: 0;
    overflow: hidden;
   .clouds_one {
      background: url("../img/cloud_one.png");
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 300%;
      -webkit-animation: cloud_one 70s linear infinite;
      -webkit-transform: translate3d(0, 0, 0);
    }
    .clouds_two {
      background: url("../img/cloud_two.png");
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 300%;
      -webkit-animation: cloud_two 90s linear infinite;
      -webkit-transform: translate3d(0, 0, 0);
    }

    .clouds_three {
      background: url("../img/cloud_three.png");
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 300%;
      -webkit-animation: cloud_three 140s linear infinite;
      -webkit-transform: translate3d(0, 0, 0);
    }
  }
  .row {
    margin: 0 auto;
  }


}

@-webkit-keyframes cloud_one {
  0% {
    left: 0
  }
  100% {
    left: -200%
  }
}

@-webkit-keyframes cloud_two {
  0% {
    left: 0
  }
  100% {
    left: -200%
  }
}

@-webkit-keyframes cloud_three {
  0% {
    left: 0
  }
  100% {
    left: -200%
  }
}