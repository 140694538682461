* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  background: #FFF;
}
body,html{
  height: 100%;
  min-height: 100vh;
}
body {
  font-family: "Raleway", sans-serif;
  font-size: 15.5px;
  line-height: 160%;
  font-weight: 400;
  color: #898B8E;
}

a {
  color: rgba($purple,.5);
  text-decoration: none;
  transition: all .2s ease-in;
  &:hover {
    color: $purple;
    text-decoration: none
  }
}