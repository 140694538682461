header {
  height: 100vh;
}

section {
  padding-top: 50px;
  padding-bottom: 60px;
}

.section-alt {
  background: rgba($purple, .08);
}


.section-header {
  text-align: center;
  font-family: 'Codystar', sans-serif;
  margin-bottom: 20px;
  font-size: 30px;
  text-shadow: 0px 0px 1px black;
}

.text-center {
  text-align: center;
}

.product-image {
  width: 100%;
}

section.team {
  h3,
  h4 {
    display: block;
    text-align: center;
    font-weight: 700;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    margin: 10px auto 40px;
    font-size: 16px;
  }
  img {
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 20px auto 30px;
    border-radius: 50%;
    border: 5px solid;
    max-height: 240px;
    &.purple {
      border-color: rgba($purple, .5);
    }
    &.green {
      border-color: rgba($green, .5);
    }
  }
}

section.recognition {
  img {
    &.full-img {
      width: 100%;
      max-width: 320px;
      margin: 62px auto;
    }
    &.half-img {
      max-height: 160px;
      max-width: 35%;
      margin: 0 5% 30px;
      display: inline;
    }
  }
}

section.memberships {
  img {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
    &.chart {
      border-radius: 100%;
    }
  }
}