button {
  border: 0;
  background: $blue;
  color: $white;
  padding: 5px 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: all .3s ease-in;
  &:hover {
    background: rgba($blue, .8);
  }
}