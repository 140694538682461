::selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-moz-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}


@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes deblur {
  0% {
    opacity: 0;
    transform: translate3d(0, -80px, 0);
    filter: blur(100px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    filter: blur(0px);
  }
}
